import moment from 'moment'
import { Paging, UserDto } from '.'

export interface IPlayer {
	placement: number
	prevPlacement: number
	wr: number
	playerId: number
	name: string
	firstName: string
	fullName: string
	lastName: string
	year: number
	age: number
	ageClass: string
	gender: TGender
	para: number
	isForeigner: boolean
	country: string
	championshipApproved: boolean
	swedishCitizen: boolean
	isMale: boolean
	isFemale: boolean
	inactive: boolean
	isPara: boolean
	class: TPlayerClass
	club: string
	clubData: {
		alias: string
		name: string
		clubId: number
		image: string
	}
	districtData: {
		alias: string
		districtId: number
		image: string
		name: string
		region: string
	}
	loans: {
		league: string
		club: string
		clubImage: string
	}[]
	license: boolean
	ranking: number
	point: number
	created?: number
	birthDate?: string
	isToYoung?: boolean
}

export type TGender = 'M' | 'K'

export type TPlayerClass =
	| 'HE'
	| 'H1'
	| 'H2'
	| 'H3'
	| 'H4'
	| 'H5'
	| 'H6'
	| 'H7'
	| 'DE'
	| 'D1'
	| 'D2'
	| 'D3'
	| 'D4'
	| 'D5'

export class Player implements IPlayer {
	placement: number
	prevPlacement: number
	wr: number
	playerId: number
	firstName: string
	lastName: string
	year: number
	birthDate?: string
	isToYoung?: boolean
	country: string
	isForeigner: boolean
	championshipApproved: boolean
	swedishCitizen: boolean
	gender: TGender
	class: TPlayerClass
	para: number
	license: boolean
	inactive: boolean
	club: string
	clubData: {
		alias: string
		name: string
		clubId: number
		image: string
	}
	districtData: {
		alias: string
		districtId: number
		image: string
		name: string
		region: string
	}
	loans: {
		league: string
		club: string
		clubImage: string
	}[]
	ranking: number
	point: number
	created?: number

	constructor(p: IPlayer) {
		this.placement = p.placement
		this.prevPlacement = p.prevPlacement
		this.wr = p.wr
		this.playerId = p.playerId
		this.firstName = p.firstName
		this.lastName = lastNameParse(p.lastName)
		this.year = p.year
		this.birthDate = p.birthDate
		this.gender = p.gender
		this.para = p.para
		this.class = p.class
		this.club = p.club
		this.clubData = p.clubData
		this.districtData = p.districtData
		this.license = p.license
		this.ranking = p.ranking
		this.point = p.point
		this.isToYoung = p.isToYoung || false
		this.isForeigner = p.isForeigner
		this.championshipApproved = p.championshipApproved
		this.country = p.country
		this.created = p.created
		this.inactive = p.inactive
		this.swedishCitizen = p.swedishCitizen
		this.loans = p.loans
	}

	get name(): string {
		return this.firstName + ' ' + this.lastName
	}

	get fullName(): string {
		return this.lastName + ', ' + this.firstName
	}

	get isMale(): boolean {
		return this.gender.includes('M')
	}

	get isFemale(): boolean {
		return this.gender.includes('K')
	}

	get isPara(): boolean {
		return !!this.para
	}

	get age(): number {
		return +moment().diff(`${this.year}0101`, 'years')
	}

	get ageClass(): string {
		// Seniors belongs to the veteran class by the calendar year
		let age = +moment().diff(`${this.year}0701`, 'years')
		if (age <= 20) {
			// Juniors belongs to the junior class by the season year
			if (age < 17) {
				return `${this.isMale ? 'P' : 'F'}${age}`
			} else {
				return `${this.isMale ? 'H' : 'D'}J${age}`
			}
		} else {
			age += 1
			if (age >= 90) {
				return `${this.isMale ? 'H' : 'D'}90`
			} else if (age >= 40) {
				const minus = age % 5
				return `${this.isMale ? 'H' : 'D'}${age - minus}`
			} else {
				return this.age.toString()
			}
		}
	}
}

const lastNameParse = (lastName: string): string => {
	return lastName
		.replace(/Von /g, 'von ')
		.replace(/Van Den/g, 'van den')
		.replace(/Van Der/g, 'van der')
		.replace(/De La /g, 'de la ')
		.replace(/De la /g, 'de la ')
		.replace(/Van /g, 'van ')
		.replace(/Af /g, 'af ')
		.replace(/Da /g, 'da ')
}

export interface PlayerPaging extends Paging {
	docs: IPlayer[]
}

export interface IClimber {
	placement: number
	prevPlacement: number
	advance: number
	playerId: number
	firstName: string
	lastName: string
	gender: string
	isMale: boolean
	isFemale: boolean
	name: string
	fullName: string
	class: string
	club: string
	ranking: number
	point: number
	inactive: boolean
}

export class Climber {
	placement: number
	prevPlacement: number
	playerId: number
	firstName: string
	gender: string
	lastName: string
	class: string
	club: string
	ranking: number
	advance: number
	point: number
	inactive: boolean

	constructor(c: IClimber) {
		this.placement = c.placement
		this.prevPlacement = c.prevPlacement
		this.playerId = c.playerId
		this.firstName = c.firstName
		this.lastName = c.lastName
		this.class = c.class
		this.club = c.club
		this.ranking = c.ranking
		this.point = c.point
		this.advance = c.advance
		this.gender = c.gender
		this.inactive = c.inactive
	}

	get name(): string {
		return this.firstName + ' ' + this.lastName
	}

	get fullName(): string {
		return this.firstName + ' ' + this.lastName
	}

	get isMale(): boolean {
		return (this.gender && this.gender.includes('M')) || (this.class && this.class.includes('H'))
	}

	get isFemale(): boolean {
		return (this.gender && this.gender.includes('K')) || (this.class && this.class.includes('D'))
	}
}

export interface IHighScoreItem {
	placement: number
	prevPlacement: number
	class: TPlayerClass
	club: string
	ranking: number
	advance: number
	point: number
	created: Date
	type: string
}

export interface IHighScorer {
	playerId: number
	firstName: string
	lastName: string
	fullName: string
	name: string
	items: IHighScoreItem[]
	updated: number
	total: number
	rockets: number
	comets: number
}

export class HighScorer {
	playerId: number
	firstName: string
	lastName: string
	updated: number
	items: IHighScoreItem[]

	constructor(c: IHighScorer) {
		this.playerId = c.playerId
		this.firstName = c.firstName
		this.lastName = c.lastName
		this.updated = c.updated
		this.items = c.items
	}

	get name(): string {
		return this.firstName + ' ' + this.lastName
	}

	get fullName(): string {
		return this.firstName + ' ' + this.lastName
	}

	get comets(): number {
		return this.items.filter(({ type }) => type === 'comet').length
	}

	get rockets(): number {
		return this.items.filter(({ type }) => type === 'rocket').length
	}

	get total(): number {
		return this.items.length
	}
}

export interface VersusMatch {
	win: boolean
	wo: boolean
	point: number
	ranking: number
	opponentRanking: number
	date: string
}

export interface Statistic {
	date: string
	ranking: number
	placement: number
	point: number
	total: number
	correction: number
	correctionStartRanking: number
	inactive: number
}

export interface Career {
	wins: number
	loses: number
	percentage: number
	careerLength: number
	start: Date
	months: number
}

export interface Match {
	date: string
	opponentName: string
	opponentRanking: string
	point: number
	win: boolean
	wo: boolean
}

export interface Month {
	point: number
	total: number
	matchPoint: number
	date: string
	matches: Match[]
	placement: number
	prevPlacement: number
	primaryStartRanking: number
	correctionStartRanking: number
	correction: number
	inactive: number
	ranking: number
	rid: number
	open?: boolean
	wins?: number
	loses?: number
}

export interface HFMatch {
	win: boolean
	wo: boolean
	point: number
	opponentRanking: number
	opponentName: string
	date: Date
	ridDate: Date
	myRanking: number
	diff: number
}

export interface HallOfFameSlideItem {
	title: string
	slide: HallOfFameSlide
	active?: string
}

export interface HallOfFameSlide {
	winner: HFMatch | HFOccurrence | HFStreak | HFMonth | HFSeason
	runnersUp: Array<HFMatch | HFOccurrence | HFStreak | HFMonth | HFSeason>
}

export interface HallOfFame {
	highestPlacement: HallOfFameSlide
	highestPointWin: HallOfFameSlide
	highestRanking: HallOfFameSlide
	highestRankedOpponentWin: HallOfFameSlide
	longestStreak: HallOfFameSlide
	mostMatchDifferenceInSeason: HallOfFameSlide
	mostMatchesInMonth: HallOfFameSlide
	mostMatchesInSeason: HallOfFameSlide
	mostPlayedOpponent: HallOfFameSlide
	mostPointsAgainstOpponent: HallOfFameSlide
	mostPointsInMonth: HallOfFameSlide
	mostPointsInSeason: HallOfFameSlide
	mostWinsAgainstOpponent: HallOfFameSlide
	mostWinsDifferenceAgainstOpponent: HallOfFameSlide
	mostWinsInMonth: HallOfFameSlide
	mostWinsInSeason: HallOfFameSlide
}

export interface HFOccurrence {
	name: string
	count: number
	matches: HFMatch[]
	sum: number
	wins: number
	loses: number
	diff: number
}

export interface HFStreak {
	start: Date
	end: Date
	days: number
	count: number
}

export interface HFMonth {
	point: number
	date: Date
	matchCount: number
	winCount: number
	placement: number
	ranking: number
}

export interface HFSeason {
	name: string
	wins: number
	loses: number
	wos: number
	diff?: number
	totalMatches: number
	totalPoint: number
}

export interface HFSegmentLabel {
	value: string
	label: string
}

export interface HFOptions {
	runnersUpSize: number
}

export interface BadgeItem {
	advance: number
	class: string
	point: number
	club: string
	created: Date
	placement: number
	prevPlacement: number
	ranking: number
	type: string
}

export interface Badge {
	firstName: string
	lastName: string
	items: BadgeItem[]
	playerId: number
	updated: Date
}

export interface Profile {
	user: UserDto
	badge: Badge
	player: Player
	months: Month[]
}

export interface Transfer {
	lastName: string
	firstName: string
	// birth: Date
	oldClub: string
	oldClubImage?: string
	newClub: string
	newClubImage?: string
	transferDate: Date
	transferTo: string
	playerId: number
	hasPassed: boolean
}

export interface Loan {
	loanId: string
	lastName: string
	firstName: string
	birthDate: Date
	homeClub: string
	homeClubImage?: string
	loanClub: string
	loanClubImage?: string
	leagues: string[]
	loanDate: Date
	playerId?: number
	hasPassed: boolean
	loanTo: string
}
