import { Paging } from './generic.interface'

export interface MedalCreateDto {
	playerId: number
	partnerId?: number
	playerIdList?: number[]
	year: number
	championshipType: string
	event: string
	value: string
	comment?: string
}

export interface Medal {
	medalId?: string
	year: number
	event: string
	value: MedalValueEnum
	championshipType: string
	comment?: string
	player: {
		firstName: string
		lastName: string
		club: string
		year: number
		gender: 'M' | 'K'
	}
}

export enum MedalValueEnum {
	GOLD = 'GULD',
	SILVER = 'SILVER',
	BRONZE = 'BRONS'
}

export interface MedalFilter {
	page: number
	championshipType: string
	eventType: string
	limit?: number
	search?: string
	sort?: object
}

export interface MedalPaging extends Paging {
	docs: Medal[]
}
