import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { AvatarModule } from 'ngx-avatars'
import { ClassBadgeComponent } from './class-badge/class-badge.component'
import { DistrictPickerComponent } from './district-picker/district-picker.component'
import { FlagComponent } from './flag/flag.component'
import { PlayerAvatarComponent } from './player-avatar/player-avatar.component'
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component'

@NgModule({
	declarations: [
		ScrollToTopComponent,
		PlayerAvatarComponent,
		ClassBadgeComponent,
		DistrictPickerComponent,
		FlagComponent
	],
	imports: [IonicModule, CommonModule, AvatarModule],
	exports: [ScrollToTopComponent, PlayerAvatarComponent, ClassBadgeComponent, DistrictPickerComponent, FlagComponent]
})
export class ComponentsModule {}
