import { Injectable } from '@angular/core'
import { Favorite, List, Opponent, Player, TokensModel } from '@app/shared/interfaces'
import { Storage } from '@ionic/storage'
import { BehaviorSubject, Observable, from } from 'rxjs'

const ACCESS_TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	private _storage: Storage | null = null
	currentPlayerState = new BehaviorSubject<Player>({} as Player)
	opponentsState = new BehaviorSubject<Opponent[]>([])
	favoritesState = new BehaviorSubject<Favorite[]>([])
	listsState = new BehaviorSubject<List[]>([])
	activeListState = new BehaviorSubject<List>({} as List)

	constructor(private storage: Storage) {
		this.init()
	}

	async init() {
		// If using, define drivers here: await this.storage.defineDriver(/*...*/);
		const storage = await this.storage.create()
		this._storage = storage
	}

	setTokens({ access_token, refresh_token }: TokensModel): Observable<any> {
		const storeAccess = this._storage.set(ACCESS_TOKEN_KEY, access_token)
		const storeRefresh = this._storage.set(REFRESH_TOKEN_KEY, refresh_token)

		return from(Promise.all([storeAccess, storeRefresh]))
	}

	removeTokens(): Observable<any> {
		const removeAccess = this._storage.remove(ACCESS_TOKEN_KEY)
		const removeRefresh = this._storage.remove(REFRESH_TOKEN_KEY)

		return from(Promise.all([removeAccess, removeRefresh]))
	}

	getAccessToken(): Observable<any> {
		return from(this.storage.get(ACCESS_TOKEN_KEY))
	}

	getRefreshToken(): Observable<any> {
		return from(this.storage.get(REFRESH_TOKEN_KEY))
	}

	// #region currentPlayer
	setCurrentPlayer(player: Player) {
		this.currentPlayerState.next(player)
	}

	filterCurrentPlayerFromOpponent({ playerId }: Player) {
		const opponents = this.opponentsState.getValue().filter(({ player }) => player.playerId !== playerId)

		this.opponentsState.next(opponents)
	}
	// #endregion

	// #region opponents
	setOpponents(opponents: Opponent[]) {
		this.opponentsState.next(opponents)
	}

	addOpponent(opponent: Opponent) {
		this.opponentsState.next([...this.opponentsState.getValue(), opponent])
	}

	updateOpponent(opponent: Opponent) {
		const opponents = this.opponentsState.getValue()
		const index = opponents.findIndex(({ opponentId }) => opponentId === opponent.opponentId)
		opponents[index] = opponent
		this.opponentsState.next(opponents)
	}

	removeOpponent({ opponentId }: Opponent) {
		const opponents = this.opponentsState.getValue().filter((o) => o.opponentId !== opponentId)
		this.opponentsState.next(opponents)
	}
	// #endregion

	// #region lists
	setActiveList(list: List) {
		this.activeListState.next(list)
	}

	setList(lists: List[]) {
		this.listsState.next(lists)
	}

	addList(list: List) {
		this.listsState.next([...this.listsState.getValue(), list])
	}

	updateList(list: List) {
		const lists = this.listsState.getValue()
		const index = lists.findIndex(({ listId }) => listId == list.listId)
		lists[index] = list
		this.listsState.next(lists)
	}

	removeList({ listId }: List) {
		if (this.activeListState.getValue().listId === listId) {
			this.activeListState.next({} as List)
			this.resetCalcStates()
		}
		const lists = this.listsState.getValue().filter((l) => l.listId !== listId)
		this.listsState.next(lists)
	}
	// #endregion

	// #region favorites
	setFavorites(favorites: Favorite[]) {
		this.favoritesState.next(favorites)
	}

	addFavorite(favorite: Favorite) {
		this.favoritesState.next([...this.favoritesState.getValue(), favorite])
	}

	removeFavorite({ favoriteId }: Favorite) {
		const favorites = this.favoritesState.getValue().filter((o) => o.favoriteId !== favoriteId)
		this.favoritesState.next(favorites)
	}
	// #endregion

	resetCalcStates() {
		this.currentPlayerState.next({} as Player)
		this.opponentsState.next([])
	}

	clearStorage() {
		this.resetCalcStates()
		this.activeListState.next({} as List)
		this.listsState.next([])
		this.removeTokens()
	}
}
